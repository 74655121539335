//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
// Media imports

import { Grid, LinearProgress, Slider, SxProps, TextField, Tooltip } from "@mui/material";
import { VTButton, VTDialog, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";

import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import {
    AddRounded,
    CancelRounded,
    SaveRounded,
    DeleteRounded,
    VolumeOffRounded,
    VolumeUpRounded,
    SettingsRounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
    setAllowAutoPlay,
    setCurrentAudio,
    setCurrentHotspot,
    setCurrentQuestion,
} from "../../store/slices/current.slice";
import { useDispatch } from "react-redux";
import { getTimeElapsedInStopWatchFormatString } from "../../utils/getTimeElapsedInStopWatchFormattedString";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Params, useParams } from "react-router-dom";
import { blankQuestionHotspot } from "../../helpers/hotspotDataGenerator";
import { useAppSelector } from "../../store/hooks";
import { useUpdateHotspotMutation } from "../../services/hotspot.service";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    videoCurrentTime: number;
    setVideoCurrentTime: (videoCurrentTime: number) => void;
    videoCurrentState: string | undefined;
    setVideoCurrentState: any;
    video: HTMLVideoElement;
    videoNextQuestion: IHotspot | undefined;
    getNextQuestion: (time: number) => void;
    setVideoNextQuestion: (videoNextQuestion: IHotspot | undefined) => void;

    setTimelineMode: (timelineMode: "Scenes" | "Timeline" | "Stepper") => void;
    currentInterfaceTrue: boolean;
    sx?: SxProps;
    bottomDrawWidth: number;
    hotspots: IHotspot[] | undefined;
    setVideoSettingDialog?: (videoSettingDialog: boolean) => void;
    sortedQuestions: IHotspot[];
    setSortedQuestions: (sortedQuestions: IHotspot[]) => void;
    createHotspot: any;
    deleteHotspot: any;
    setHotspotLoading: (hotspotLoading: string) => void;
    setVideoTimelineLoading: (videoTimelineLoading: string) => void;
    setVideoFirstPlay: (videoFirstPlay: boolean) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorVideoControls({
    videoCurrentTime,
    setVideoCurrentTime,
    videoCurrentState,
    setVideoCurrentState,
    video,
    videoNextQuestion,
    getNextQuestion,
    setVideoNextQuestion,
    setTimelineMode,
    sx,
    currentInterfaceTrue,
    bottomDrawWidth,
    hotspots = [],
    sortedQuestions,
    setSortedQuestions,
    createHotspot,
    deleteHotspot,
    setVideoTimelineLoading,
    setVideoFirstPlay,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useDispatch();
    const [updateHotspot] = useUpdateHotspotMutation();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [currentTimeEditing, setCurrentTimeEditing] = useState<boolean>(false);
    const [currentTimeEditInput, setCurrentTimeEditInput] = useState<number>(videoCurrentTime);
    const [currentlyMuted, setCurrentMuted] = useState<boolean>(false);
    const [previousQuestionTime, setPreviousQuestionTime] = useState<number>(0);
    const { currentAudio, allowAutoPlay } = useAppSelector((state) => state.currentReducer);

    const [editStopPointTimeOpen, setEditStopPointTimeOpen] = useState<boolean>(false);
    const [editStopPointTimeDisabled, setEditStopPointTimeDisabled] = useState<boolean>(false);
    const [editStopPointTime, setEditStopPointTime] = useState<number>(0);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (video.muted !== currentlyMuted) {
            setCurrentMuted(video.muted);
        }
    }, [video.muted]);

    useEffect(() => {
        if (editStopPointTime !== 0) {
            setEditStopPointTimeDisabled(
                sortedQuestions.filter(
                    (findTimeElement: IHotspot) =>
                        videoNextQuestion !== findTimeElement &&
                        findTimeElement.contents.preClickContent.question!.time - 0.3 <= editStopPointTime &&
                        findTimeElement.contents.preClickContent.question!.time + 0.3 >= editStopPointTime,
                ).length > 0,
            );
        }
    }, [editStopPointTime]);

    useEffect(() => {
        if (videoNextQuestion && hotspots) {
            let tempPreviousQuestionTime = 0;
            hotspots
                .filter((hotspotFilterElement: IHotspot) => hotspotFilterElement.type === EDraggableOptions.QUESTION)
                .forEach((hotspotElement: IHotspot) => {
                    if (
                        hotspotElement.contents.preClickContent.question!.time > tempPreviousQuestionTime &&
                        hotspotElement.contents.preClickContent.question!.time <
                            videoNextQuestion.contents.preClickContent.question!.time
                    ) {
                        tempPreviousQuestionTime = hotspotElement.contents.preClickContent.question!.time;
                    }
                });

            setPreviousQuestionTime(tempPreviousQuestionTime);
        } else if (hotspots) {
            let tempPreviousQuestionTime = 0;
            hotspots
                .filter((hotspotFilterElement: IHotspot) => hotspotFilterElement.type === EDraggableOptions.QUESTION)
                .forEach((hotspotElement: IHotspot) => {
                    if (hotspotElement.contents.preClickContent.question!.time > tempPreviousQuestionTime) {
                        tempPreviousQuestionTime = hotspotElement.contents.preClickContent.question!.time;
                    }
                });

            setPreviousQuestionTime(tempPreviousQuestionTime);
        }
    }, [videoNextQuestion, hotspots]);

    let BufferedZones = () => {
        return (
            <>
                {Array.from(Array(video.buffered.length), (e, i) => {
                    return (
                        <div
                            style={{
                                position: "absolute",
                                left: `${(video.buffered.start(i) / video.duration) * 100}%`,
                                right: `${100 - (video.buffered.end(i) / video.duration) * 100}%`,
                                backgroundColor: "#ae45ff",
                                height: "4px",
                                top: "-2px",
                            }}
                        />
                    );
                })}
            </>
        );
    };

    let SliderZone = () => {
        return (
            <Slider
                sx={{
                    width: "calc(100% - " + 100 + "px)",
                    zIndex: 1000,
                }}
                value={videoCurrentTime}
                max={video.duration}
                onChange={(_, value) => {
                    if (videoCurrentTime && typeof value === "number" && value < videoCurrentTime) {
                        video.currentTime = value as number;
                        getNextQuestion(value as number);
                        setVideoCurrentState("Paused");
                        video.pause();
                    }
                }}
                step={0.001}
            />
        );
    };

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // If page is large render all buttons

    return (
        <Grid
            container
            sx={{
                ...sx,
                position: "absolute",
                bottom: bottomDrawWidth === 0 ? "35px" : "12px",
                width: currentInterfaceTrue ? "calc(100% - 400px)" : "100%",
                zIndex: 1000,
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "-30px",
                    borderRadius: "15px",
                }}
            >
                {video.buffered.length >= 1 && (
                    <Grid
                        sx={{
                            width: "calc(100% - " + 100 + "px)",
                            zIndex: 800,
                            position: "relative",
                        }}
                    >
                        <BufferedZones />
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <SliderZone />
            </Grid>

            {/*-------------------------------------------------------------------------------*/}
            {/* Rewind button */}
            <Grid item container xs={12} alignItems={"center"} justifyContent={"center"}>
                <VTButton
                    sx={{ marginRight: "5px" }}
                    type={"icon"}
                    onClick={() => {
                        video.currentTime = videoCurrentTime - 5;
                        getNextQuestion(videoCurrentTime - 5);
                        if (videoCurrentState === "Question") {
                            setVideoCurrentState("Paused");
                        }
                    }}
                    disabled={videoCurrentTime <= 5 && true}
                >
                    <FastRewindRounded />
                </VTButton>

                {/*-----------------------------------------------------------------------------*/}
                {/* Play / Pause controls */}
                {videoCurrentState === "Playing" ? (
                    <VTButton
                        sx={{ marginRight: "5px" }}
                        type={"icon"}
                        onClick={() => {
                            video.pause();
                            setVideoCurrentState("Paused");
                        }}
                    >
                        <PauseRounded />
                    </VTButton>
                ) : (
                    <VTButton
                        sx={{ marginRight: "5px" }}
                        type={"icon"}
                        onClick={() => {
                            if (videoCurrentState !== "Question") {
                                getNextQuestion(videoCurrentTime);

                                if (currentAudio !== "") {
                                    dispatch(setCurrentAudio(""));
                                }
                                setVideoCurrentState("Playing");
                                video.play();
                                if (allowAutoPlay === false) {
                                    dispatch(setAllowAutoPlay(true));
                                }

                                setVideoFirstPlay(false);
                            }
                        }}
                    >
                        <PlayArrowRounded />
                    </VTButton>
                )}

                {/*-----------------------------------------------------------------------------*/}
                {/* Current time / duration */}

                <Grid
                    sx={{
                        width: "135px",
                        backgroundColor: "rgba(0,0,0,0.2)",
                        borderRadius: "15px",
                        padding: "2px",
                    }}
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                >
                    <div
                        style={{
                            fontFamily: "Digit",
                            fontSize: "0.9em",
                        }}
                    >
                        {getTimeElapsedInStopWatchFormatString(videoCurrentTime * 1000)}
                    </div>

                    <span
                        style={{
                            fontSize: "1.3",
                            marginLeft: "6px",
                            marginRight: "2px",
                            marginTop: "-1px",
                        }}
                    >
                        /
                    </span>

                    <div style={{ fontFamily: "Digit", fontSize: "0.9em" }}>
                        {video.duration ? getTimeElapsedInStopWatchFormatString(video.duration * 1000) : "00:00:00"}
                    </div>
                </Grid>

                {video && currentlyMuted ? (
                    <VTButton
                        onClick={() => {
                            video.muted = false;
                            localStorage.setItem("video_muted", "false");
                            setCurrentMuted(false);
                        }}
                        sx={{ marginLeft: "15px" }}
                        type={"icon"}
                    >
                        <VolumeOffRounded />
                    </VTButton>
                ) : (
                    <VTButton
                        onClick={() => {
                            video.muted = true;
                            localStorage.setItem("video_muted", "true");
                            setCurrentMuted(true);
                        }}
                        sx={{ marginLeft: "15px" }}
                        type={"icon"}
                    >
                        <VolumeUpRounded />
                    </VTButton>
                )}
            </Grid>
        </Grid>
    );
}
